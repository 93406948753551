import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['indicator'];

  click(e) {
    if (e.target.closest('a, button, input, menu, .table__checkbox, .table__row-indicator-cell')) return;

    this.hideIndicators();
    this.indicatorTarget.classList.add('table__row-indicator');
    this.dispatch('click');
  }

  hideIndicators() {
    document.querySelectorAll('.table__row-indicator').forEach((el) => el.classList.remove('table__row-indicator'));
  }
}
