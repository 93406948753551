import { Controller } from '@hotwired/stimulus';
import { sortable } from '@modules/sortable';

export default class extends Controller {
  static targets = ['list', 'indicator'];

  static values = { offset: Number };

  connect() {
    sortable(this);
  }

  updateOffset({ detail: { offset } }) {
    this.offsetValue = offset;
  }

  updateIndicators() {
    this.indicatorTargets.forEach((indicator, index) => {
      indicator.textContent = index + 1;
    });
  }
}
