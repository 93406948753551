import { Controller } from '@hotwired/stimulus';
import { useMutation, useWindowResize } from 'stimulus-use';

export default class extends Controller {
  static targets = ['toolbar', 'checkboxAll'];

  connect() {
    useMutation(this, { attributes: true, subtree: true, childList: true });
    useWindowResize(this);
    this.mutate();
  }

  windowResize() {
    this.#resizeToolbar();
  }

  mutate() {
    if (!this.hasCheckboxAllTarget) return;

    if ((this.checkboxAllTarget.checked || this.checkboxAllTarget.ariaChecked === 'mixed')) {
      this.toolbarTarget.style.zIndex = 2;
      this.toolbarTarget.style.opacity = 1;
    } else {
      this.toolbarTarget.style.opacity = 0;

      setTimeout(() => {
        this.toolbarTarget.style.zIndex = -1;
      }, 500);
    }
  }

  #resizeToolbar() {
    const header = this.element.querySelector('thead') || this.element;
    const rect = header.getBoundingClientRect();
    const checkbox = header.querySelector('.table__checkbox, .mdc-data-table__header-cell');
    const { width } = checkbox.getBoundingClientRect();

    this.toolbarTarget.style.left = `${width}px`;
    this.toolbarTarget.style.width = `${rect.width - (0.25 * width) - 59}px`;
  }
}
