/* global Turbo */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['sort'];

  update(e) {
    this.sortTargets.forEach((el) => el.classList.remove('sorted'));

    const current = e.target;
    current.classList.add('sorted');

    window.history.pushState({}, '', current.href);
    Turbo.navigator.history.push(current.href);

    const url = new URL(current.href);
    sessionStorage.setItem(url.pathname, url.search);

    this.dispatch('sorted', { detail: { current } });
  }

  updateDirection({ detail: { current } }) {
    const direction = this.toggle(current.dataset.direction);
    current.setAttribute('data-direction', direction);
    this.updateUrl(current, direction);
  }

  updateUrl(element, direction) {
    const updatedUrl = new URL(element.href);
    updatedUrl.searchParams.set('direction', direction);
    element.href = updatedUrl.toString();
  }

  toggle(direction) {
    return (direction === 'ascending') ? 'descending' : 'ascending';
  }
}
