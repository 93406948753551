import CheckboxSelectAll from '@stimulus-components/checkbox-select-all';

export default class extends CheckboxSelectAll {
  static targets = ['body'];

  static values = { storageKey: String };

  initialize() {
    super.initialize();
    this.checkboxTargetChanged = this.checkboxTargetChanged.bind(this);
  }

  checkboxTargetConnected(checkbox) {
    const selectedRowIds = sessionStorage.getItem(this.storageKeyValue);
    const rowId = checkbox.value;

    if (selectedRowIds && selectedRowIds.includes(rowId)) checkbox.checked = true;
    super.checkboxTargetConnected(checkbox);

    this.dispatch('checkboxChanged');
    checkbox.addEventListener('change', this.checkboxTargetChanged);
  }

  checkboxTargetChanged() {
    if (this.checkboxAllTarget.checked) {
      sessionStorage.setItem(this.storageKeyValue, this.checkedRows());
    } else {
      sessionStorage.removeItem(this.storageKeyValue);
    }

    this.dispatch('checkboxChanged');
  }

  checkboxAllTargetChanged() {
    if (this.checkboxAllTarget.checked) {
      sessionStorage.setItem(this.storageKeyValue, this.checkboxTargets.map((input) => input.value));
    } else {
      sessionStorage.removeItem(this.storageKeyValue);
    }

    this.dispatch('checkboxChanged');
  }

  checkedRows() {
    return this.checked.map((input) => input.value).join(',');
  }
}
