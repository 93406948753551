/* global i18n */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['count', 'checkbox'];

  static values = { total: String };

  connect() {
    this.update();
  }

  update() {
    this.setCount(this.checkboxTargets.filter((el) => el.checked).length);
  }

  selectAll(e) {
    this.setCount(e.currentTarget.checked ? this.checkboxTargets.length : 0);
  }

  setCount(count) {
    this.countTarget.innerText = i18n.t('js.pagy.selected_count', { count, total: this.totalValue });
  }
}
