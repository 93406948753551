import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form'];

  static values = { storageKey: String };

  connect() {
    this.update();
  }

  update() {
    const selected = this.selectedRowIds().length;

    this.updateTargetUrls();

    if (selected) {
      this.enableTableActions();
    } else {
      this.disableTableActions();
    }
  }

  enableTableActions() {
    this.formTargets.forEach((form) => {
      form.querySelector('button').removeAttribute('disabled');
    });
  }

  disableTableActions() {
    this.formTargets.forEach((form) => {
      form.querySelector('button').setAttribute('disabled', true);
    });
  }

  selectedRowIds() {
    const rowIds = sessionStorage.getItem(this.storageKeyValue);
    if (!rowIds) return [];

    return rowIds.split(',');
  }

  addParamsToUrl(url, selectedRowIds) {
    url.searchParams.set('row_ids', selectedRowIds);
    return url.toString();
  }

  addParamsToForm(form, selectedRowIds) {
    let input = form.elements.row_ids;

    if (input) {
      input.value = selectedRowIds;
    } else {
      input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'row_ids';
      input.value = selectedRowIds;
      form.appendChild(input);
    }
  }

  updateTargetUrls() {
    const selectedRowIds = this.selectedRowIds();

    this.formTargets.forEach((form) => {
      this.addParamsToForm(form, selectedRowIds);
    });
  }
}
