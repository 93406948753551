import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  update(e) {
    const { checked, value } = e.currentTarget;

    if (checked) {
      this.show(value);
    } else {
      this.hide(value);
    }

    this.dispatch('changed');
  }

  show(name) {
    this.targets.findAll(name).forEach((el) => el.classList.remove('d-none'));
  }

  hide(name) {
    this.targets.findAll(name).forEach((el) => el.classList.add('d-none'));
  }
}
